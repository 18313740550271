import * as TradingViewApi from 'api/trading';
import historyProvider from './historyProvider';
import _ from 'lodash';
if (!window.tradingView) {
  window.tradingView = {};
}

let counter = 0
let prevUid = ''
let unsubscribed = []
let UIDs = {}

const createUID = function (pair, exchange, resolution, subscriberUID) {
  // USDT-ETH / mex_gunthy_#_ETH_#_1
  const [base, quote, direction] = pair.split('-');
  const result = `${pair} / ${exchange.toUpperCase()}_#_${quote}_#_${resolution}`
  UIDs[subscriberUID] = result
  return result
}

var timer = null

export default class Datafeed {
  constructor(options) {
    const { debug = false, symbols = [], stream, onSymbolChanged } = options;
    this.debug = debug;
    this.symbols = symbols;
    this.onSymbolChanged = onSymbolChanged;
    this.stream = stream;
  }

  binanceServerTime() {
    return TradingViewApi.serverTime();
  }

  onReady(callback) {
    setTimeout(() => {
      callback({
        supports_marks: false,
        supports_timescale_marks: true,
        supports_time: true,
        supported_resolutions: ['1', '3', '5', '15', '30'],
        has_intraday: true,
        intraday_multipliers: ['1', '5'],
        //supported_resolutions: ['1', '5', '15', '30', '1h', '2h', '4h', '6h', '12h', '24h', '3h', '7d', '14d'],
      });
    }, 0);
  }

  searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    userInput = userInput.toUpperCase();
    onResultReadyCallback(
      this.symbols
        .filter(symbol => {
          return symbol.symbol.indexOf(userInput) >= 0;
        })
        .map(symbol => {
          return {
            name: symbol.name,
            symbol: symbol.pair,
            full_name: symbol.symbol,
            description: symbol.baseAsset + ' / ' + symbol.quoteAsset,
            pair: symbol.symbol,
            ticker: symbol.name,
            news: ['stocktwits', 'headlines'],
            studies: ['EMA Cross@tv-basicstudies'],
            exchange: symbol.exchange,
            type: 'crypto',
          };
        }),
    );
  }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    this.debug && console.log('👉 resolveSymbol:', symbolName);

    function pricescale(symbol) {
      for (let filter of symbol.filters) {
        if (filter.filterType === 'PRICE_FILTER') {
          return Math.round(1 / parseFloat(filter.tickSize));
        }
      }
      return 1;
    }

    for (let symbol of this.symbols) {
      if (symbol.symbol.toUpperCase() === symbolName.toUpperCase()) {
        setTimeout(() => {
          onSymbolResolvedCallback({
            name: symbol.symbol,
            description: symbol.baseAsset + ' / ' + symbol.quoteAsset,
            pair: symbol.pair,
            ticker: symbol.ticker,
            exchange: symbol.exchange,
            type: 'crypto',
            session: '24x7',
            minmov: 1,
            pricescale: pricescale(symbol),
            timezone: 'UTC',
            has_intraday: true,
            intraday_multipliers: ['1', '60'],
            supported_resolutions: ['1', '3', '5', '15', '30'],
            has_daily: true,
            has_weekly_and_monthly: true,
            currency_code: symbol.quoteAsset,
            volume_precision: 8,
            data_status: 'streaming',
            //news: ['stocktwits', 'headlines'],
            studies: ['EMA Cross@tv-basicstudies'],
          });
        }, 0);
        return;
      }
    }

    //onResolveErrorCallback('not found');
  }

  getTimescaleMarks(symbolInfo, startDate, endDate, onDataCallback, resolution) {
    const chartResolution = window.getPeriod(symbolInfo, true)
    window.tradingView.timescaleMarks = {
      symbolInfo,
      startDate,
      endDate,
      onDataCallback,
      chartResolution,
    };
    historyProvider.getTimescaleMarks().catch(error => {
      console.log({ error });
    });
  }

  getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    
    // candles come from subscribeBars

    if (resolution === '1') {
      onHistoryCallback([], { noData: true });
    
      return;
  }

  if (resolution === '5') {
    onHistoryCallback([], { noData: true });
      
      return;
  }
    

    

  }

  subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    const chartResolution = window.getPeriod(symbolInfo, true)
    window.tvWidget.activeChart().setResolution(chartResolution)
    const UID = createUID(symbolInfo.pair, symbolInfo.exchange, chartResolution, subscriberUID)
    this.debug && console.log('👉 subscribeBars:', UID);
    if (counter > 0 && UID !== prevUid && unsubscribed.includes(UID)) {
      prevUid = UID
      unsubscribed = unsubscribed.filter(item => item !== UID);
    }
    this.stream.subscribeBars(symbolInfo, chartResolution, onRealtimeCallback, UID, onResetCacheNeededCallback);
    counter++
    prevUid = UID
  }

  unsubscribeBars(subscriberUID) {
    const UID = UIDs[subscriberUID] || subscriberUID
    if (!unsubscribed.includes(UID)) {
      unsubscribed.push(UID)
    }
    this.debug && console.log('👉 unsubscribeBars:', UID);
    this.stream.unsubscribeBars(UID);
  }

  getServerTime(callback) {
    this.binanceServerTime()
      .then(time => {
        callback(Math.floor(time / 1000));
      })
      .catch(err => {
        console.error(err);
      });
  }
}
